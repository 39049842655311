import React from 'react';
import dynamic from 'next/dynamic';
import { useI18n } from 'i18n';
import Head from 'src/components/Head';

const App = dynamic(() => import('../../src/app/views/StepByStepFlow'), { ssr: false });

export default function EditorIndex(): React.ReactElement {
  const { t } = useI18n();

  return (
    <>
      <Head title={t('app-title')} description={t('app-description')} />
      <App />
    </>
  );
}
